* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(65, 25, 159);
  background: linear-gradient(
    48deg,
    rgba(65, 25, 159, 1) 66%,
    rgba(76, 53, 156, 1) 100%
  );
  background: url("./image/background.jpg");
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 0.4em;
  width: 0.4em;
}

::-webkit-scrollbar-track {
  background: linear-gradient(
    48deg,
    rgba(65, 25, 159, 1) 66%,
    rgba(76, 53, 156, 1) 100%
  );
}

::-webkit-scrollbar-thumb {
  background-color: #12062f;
  border-radius: 0.5em;
}
